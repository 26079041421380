import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import {
  cardFlipRoot,
  CardFace as CardFaceBase,
  CardSide as CardSideBase,
  cardBack,
} from "../styles/card-flip";

import Btn from "./btn";
import Image from "./image";

import sgd01BG from "../images/sgd-bg-01-EB1C2D.png";
import sgd02BG from "../images/sgd-bg-02-D3A029.png";
import sgd03BG from "../images/sgd-bg-03-269B48.png";
import sgd04BG from "../images/sgd-bg-04-C31E33.png";
import sgd05BG from "../images/sgd-bg-05-EF402C.png";
import sgd06BG from "../images/sgd-bg-06-00AED9.png";
import sgd07BG from "../images/sgd-bg-07-FDB713.png";
import sgd08BG from "../images/sgd-bg-08-8F1838.png";
import sgd09BG from "../images/sgd-bg-09-F36D25.png";
import sgd10BG from "../images/sgd-bg-10-E11384.png";
import sgd11BG from "../images/sgd-bg-11-F99D25.png";
import sgd12BG from "../images/sgd-bg-12-CF8D2A.png";
import sgd13BG from "../images/sgd-bg-13-47773E.png";
import sgd14BG from "../images/sgd-bg-14-017DBC.png";
import sgd15BG from "../images/sgd-bg-15-3EB04A.png";
import sgd16BG from "../images/sgd-bg-16-02558B.png";
import sgd17BG from "../images/sgd-bg-17-183667.png";

import btnBG from "../images/bg-btn-gradient.jpg";

const { white, lightGray } = colors;

export const sdgBGImg = {
  "#EB1C2D": sgd01BG,
  "#D3A029": sgd02BG,
  "#269B48": sgd03BG,
  "#C31E33": sgd04BG,
  "#EF402C": sgd05BG,
  "#00AED9": sgd06BG,
  "#FDB713": sgd07BG,
  "#8F1838": sgd08BG,
  "#F36D25": sgd09BG,
  "#E11384": sgd10BG,
  "#F99D25": sgd11BG,
  "#CF8D2A": sgd12BG,
  "#47773E": sgd13BG,
  "#017DBC": sgd14BG,
  "#3EB04A": sgd15BG,
  "#02558B": sgd16BG,
  "#183667": sgd17BG,
};

const gradient = ({ color }) => css`
  background-image: url(${sdgBGImg[color] || sgd01BG});
  background-size: cover;
`;

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CardBg = styled.div`
  ${cover};
  ${gradient};
  pointer-events: none;
  z-index: 3;
`;

export const cardBgImg = css`
  ${cover};
  position: absolute !important; /* override gatsby-image */
  z-index: 1;
`;

export const CardImgOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  z-index: 2;
`;

const hoverEffect = css`
  transform: translate3d(0, ${rem(-10)}, 0);
`;

export const Card = styled.article`
  ${({ allowFlip }) => allowFlip && "cursor: pointer"};
  width: ${rem(300)};
  height: ${rem(360)};
  ${({ hoverFx }) => hoverFx && hoverEffect};
  transition: transform 0.1s linear;
  ${cardFlipRoot};
`;

const cardShadow = ({ hoverFx, shadowColor, shadow, flipped }) => {
  if (!shadow || !hoverFx) {
    return css``;
  }

  const xPos = flipped ? "-20px" : "20px";

  return css`
    box-shadow: ${xPos} 30px 40px 0 ${shadowColor || "rgba(0,0,0,.2)"};
  `;
};

export const CardFace = styled(CardFaceBase)`
  border-radius: ${rem(30)};
  transition: box-shadow 0.5s ease-in-out, transform 0.5s;
  transform-style: preserve-3d;
  transform-origin: center right;
  ${cardShadow};
`;

const CardSide = styled(CardSideBase)`
  display: flex;
  flex-direction: column;
  border-radius: ${rem(30)};
  overflow: hidden;
  background-color: ${white};
  ${({ active }) => (!active && "pointer-events: none;") || ""};
`;

export const CardFront = styled(CardSide)``;

export const CardBack = styled(CardSide)`
  ${cardBack};
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${rem(18)} ${rem(18)} ${rem(10)};
`;

export const CardTitleBox = styled.h2`
  position: absolute;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50%;
  width: 100%;
  padding: ${rem(16)} ${rem(20)};
  margin-top: auto;
  margin-bottom: 0;
  z-index: 5;
  overflow: hidden;
  color: ${white};
  white-space: normal;
`;

export const CardTitle = styled.span`
  display: block;
  ${fontSize(23, 32)};
  font-weight: ${fontWeights.book};
`;

export const CardSubTitle = styled.span`
  display: block;
  margin-bottom: ${rem(4)};
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
`;

export const Cardsdg = styled.span`
  display: block;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.bold};
`;

export const CCHead = styled.header`
  display: flex;
  align-items: center;
`;

export const CCHeadTxt = styled.h3`
  ${fontSize(12, 16)};
  font-weight: ${fontWeights.medium};
  text-transform: uppercase;
  margin: 0;
`;

export const CCHeadsdg = styled(CCHeadTxt)`
  color: ${lightGray};
  margin-left: auto;
`;

export const CCLogo = styled(Image)`
  max-width: ${rem(30)};
  max-height: ${rem(30)};
  margin-right: ${rem(6)};
  border-radius: 100%;
  overflow: hidden;
`;

export const CCTxtBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
`;

export const challengeTxt = css`
  width: 100%;
  margin: 0;
  ${fontSize(18, 28)};
  font-weight: ${fontWeights.book};
  overflow-y: auto;
`;

export const ChallengeBtn = styled(Btn)`
  min-width: 0;
  width: ${rem(150)};
  padding: ${rem(6)} ${rem(15)};
  margin: ${rem(6)} auto ${rem(15)};
  ${fontSize(12, 16)};
  background-image: url(${btnBG});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
